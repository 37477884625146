import React from "react"

import TotalProductPage from '../components/productpage/TotalProductPage';
import MainLayout from "../components/layouts/MainLayout";

// Components
import { useGetTotalProduct } from "../hooks/useGetTotalProduct"
import { graphql } from "gatsby"

export default function Product(props) {
    const id = props?.pageContext?.id;
    const { data, loading } = useGetTotalProduct({ id });
    const productApollo = data?.product;
    const productGql = props.data?.dutchieplus.product

    return (
      <MainLayout>
        <TotalProductPage
          loading={loading}
          product={productApollo || productGql}
          preloaded={true}
        />
      </MainLayout>
    )
}

export const query = graphql`
  query ($id: ID!, $retailerId: ID!) {
    dutchieplus {
      product(id: $id, retailerId: $retailerId) {
        id
        name
        description
        category
        posMetaData {
          category
          id
        }
        subcategory
        brand {
          name
        }
        image
        strainType
        effects
        variants {
          specialPriceRec
          specialPriceMed
          quantity
          priceRec
          priceMed
          option
          id
        }
        slug
        potencyCbd {
          formatted
          range
          unit
        }
        potencyThc {
          formatted
          range
          unit
        }
      }
    }
  }
`